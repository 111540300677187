.App {
  text-align: center;
}

#root {
    height: 100%;
        background: #f8fafd !important;

}
* {
font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}


text, input, textarea, th, td  {
  font-size: 18px !important;
    color: #626d8e !important;

}

.main-color {
    color: #626d8e;
}
div {
    font-Size: 18px;
}

div > h1, div> h2, div> h3 {
    color: #626d8e;
}
/*h1 {*/
    /*font-size: 30px;*/
/*}*/
/*h2 {*/
    /*font-size: 27px;*/
/*}*/

/*h3 {*/
    /*font-size: 24px;*/
/*}*/
a {
    font-size: 14px;
    color: #626d8e;

}

label {
    font-size: 14px;
    color: #626d8e !important;
}




.list-item {
    position: relative;
    background-color: rgba(255, 255, 255, 0.05);
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 40px;
    width: 360px;
    height: 380px;
    box-sizing: border-box;
    margin-right: 60px;
    padding: 32px;
    border-radius: 5px;
}

.always-border-hovered {
    border-color: rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}


.ant-layout {
    background: #f8fafd !important;
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background: #efeff6 !important;
}

li.ant-menu-item:hover, li.ant-menu-item > a:hover,
li.ant-menu-item:hover, li.ant-menu-item > a:hover > span {
    font-weight: bold !important;
}


.ant-card-actions > li > span a:hover,
.ant-card-actions > li > span i:hover,
.ant-menu-item > div > a > button:hover  {
    border-color: #626d8e !important;
}

.ant-typography,
div.ant-col .ant-form-item-label > label,
.ant-card-meta-title, .ant-modal-title,
div.ant-card-head-title,
.ant-badge-status-text,
.ant-menu-submenu-title > span,
div.ant-row > div.ant-col,
li.ant-menu-item,
li.ant-menu-item > a,
li.ant-menu-item > div > a > .ant-btn:hover {
    color: #626d8e !important;
}

.benefit-description {
    color: #626d8e !important;
}

.faq-row > h3, .faq-row > h1 {
    font-weight: bold;
}
@media screen and (min-width: 768px) {
     .dn-above-768 {
        display: none !important;
    }
     .facebook-sign {
        text-align: right;
        position: absolute;
        right: 2%;
        top: 50px !important;
    }
}
@media screen and (max-width: 767px) {
    #hero-image{
        background-image: none!important;
    }
    .pad-top-2 {
        padding-top: 2%;
    }

    .dn-below-768 {
        display: none !important;
    }
    .mobile-text-center {
        text-align: center!important;
    }

    .center-below-768 {
        width: 100%;
        margin: 0 auto;
    }

    .ant-result-content, .ant-result-extra {
        margin-top: 0!important;
    }
    .block-below-768 {
        display: block;
    }

    .facebook-sign {
        text-align: right;
        position: absolute;
        right: 2%;
        top: 50px !important;
    }
}
@media screen and (max-width: 520px) {

     h1 {
        font-size: 24px !important;
         font-weight: bold;
    }
     h2 {
        font-size: 20px !important;
    }
     .ant-btn.ant-btn-primary.ant-btn-lg {
         height: 27px;
     }
     .ant-menu-item {
         float: none !important;
         padding: 0 13px!important;
     }
     .dn-below-520 {
         display: none !important;
     }
     .no-marg-left {
         margin-left: 0!important;
     }
}

@media screen and (max-width: 340px) {
     h1 {
        font-size: 21px !important;
    }
    h2 {
        font-size: 19px !important;
    }

    div {
        font-Size: 16px;
    }
}

#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #efeff6;
}

.ant-menu-item-selected {
    border-bottom: 2px solid #626d8e !important;
}

.anchor-link {
    font-size: 14px !important;
    color: #626d8e !important;
}

.a-l-menu-item {
    padding: 0 !important;
}
.a-l-menu-item>div {
    padding-left: 0;
}

.a-l-button {
    font-size: 13px !important;
    color: #626d8e !important;
}
.ant-select-selection-selected-value {
    color: #626d8e !important;
}

.word-break {
    word-wrap:break-word;
}

.ant-timeline-item-content {
    font-size: 15px;
    color: #626d8e !important;
}

.ant-timeline-item-last .ant-timeline-item-content {
    font-size: 16px;
    font-weight: bold;
}
.ant-result.ant-result-error {
    padding-left: 0!important;
    padding-right: 0 !important;
}

.ant-result-title {
    font-size: 20px !important;
    color: #626d8e !important;
}

.ant-result-icon > .anticon {
    font-size: 52px !important;
}
